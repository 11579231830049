.form {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 6px;
}

.input {
  font-size: 1rem;
  border-radius: 4px;
  border: none;
  padding: 12px;
  font-weight: 700;
}

textarea:focus,
input:focus {
  outline: none;
}

.button {
  background-color: #ff0046;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.1rem;
  padding: 12px;
  border: none;
  border-radius: 4px;
}
