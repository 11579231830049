.items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 48px;
}

.item {
  display: grid;
  grid-template-rows: 100px 60px auto;
  align-items: center;
  justify-items: center;
  text-align: center;
}

.icon {
  font-size: 5rem;
}

.colorRed {
  color: #ff0046;
}

@media only screen and (max-width: 600px) {
  .items {
    grid-template-columns: 1fr;
    gap: 48px;
  }
}
