h2 {
  text-transform: uppercase;
}

.item {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
}

.index {
  background-color: #ff0046;
  aspect-ratio: 1 / 1;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.2em;
  border-radius: 4px;
}
