h1 {
  margin: 0;
  font-size: 4rem;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 100%;
}

.content {
  position: absolute;
}
.video {
  right: 0;
  bottom: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 80vh;
}
