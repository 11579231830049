@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700;900&display=swap");
.container {
  color: white;
}

.text {
  text-transform: lowercase;
}

.logo {
  display: flex;
  align-items: center;
}

.icon {
  font-size: 2em;
}
